import React from 'react';

export const EmptyStateIcon = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className='[&>svg]:w-36 [&>svg]:h-36 [&>svg]:text-muted-foreground'>
      {children}
    </div>
  );
};

export const EmptyStateContext = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <div>{children}</div>;
};

export const EmptyStateTitle = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <h2 className='text-2xl font-bold'>{children}</h2>;
};

export const EmptyStateDescription = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <p className='text-muted-foreground text-sm max-w-sm w-full'>{children}</p>
  );
};

export const EmptyState = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className='min-h-[400px] w-full h-full flex items-center justify-center flex w-full flex-col text-center items-center justify-center'>
      {children}
    </div>
  );
};
