import { ScrollArea } from './ScrollArea';

export const MessagesContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <ScrollArea
      orientation='vertical'
      className='flex flex-col h-[600px] w-full'
    >
      {children}
    </ScrollArea>
  );
};
