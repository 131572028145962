import React from 'react';
import { Input, InputProps, inputVariants } from './Input';
import { VariantProps } from 'class-variance-authority';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { cn } from '../lib/utils';

const NumberInput = React.forwardRef<
  HTMLInputElement,
  InputProps &
    Omit<VariantProps<typeof inputVariants>, 'group'> & {
      rightAddon?: React.ReactNode;
    } & NumericFormatProps
>(
  (
    { className, type = 'text', size, variant, invalid, rightAddon, ...props },
    ref,
  ) => {
    return (
      <NumericFormat
        className={cn(
          'flex w-full text-sm px-3 py-2',
          inputVariants({
            variant,
            className,
            size,
            invalid,
          }),
        )}
        allowLeadingZeros={false}
        decimalSeparator='.'
        decimalScale={2}
        thousandSeparator=','
        isAllowed={(values) => {
          return true;
        }}
        onValueChange={props.onValueChange}
        {...props}
      ></NumericFormat>
    );
  },
);

NumberInput.displayName = 'NumberInput';

export { NumberInput };
