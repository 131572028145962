'use client';
import { useCallback, useState } from 'react';

export const useDisclosure = (initialIsOpen?: boolean) => {
  const [isOpen, setIsOpen] = useState(initialIsOpen || false);

  const onToggle = useCallback(() => setIsOpen((prev) => !prev), []);

  const onOpen = useCallback(() => setIsOpen(true), []);

  const onClose = useCallback(() => setIsOpen(false), []);

  return {
    isOpen,
    onToggle,
    onOpen,
    onClose,
  };
};
