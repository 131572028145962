import React from 'react';

export const SeparatorText = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className='w-full text-slate-400 relative flex py-5 items-center'>
      <div className='flex-grow border-t border-slate-300 border-dashed'></div>
      <span className='flex-shrink mx-4 text-gray-400'>{children}</span>
      <div className='flex-grow border-t border-slate-300 border-dashed'></div>
    </div>
  );
};
