'use client';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import React from 'react';
import {
  AriaButtonOptions,
  useButton,
  useLocale,
  useNumberField,
} from 'react-aria';
import { NumberFieldStateOptions, useNumberFieldState } from 'react-stately';
import { cn } from '../lib/utils';
import { Button, ButtonProps } from './Button';
import { Input, InputGroupProvider, InputProps } from './Input';

const NumberButton = ({
  className,
  variant,
  size,
  ...props
}: AriaButtonOptions<'button'> & {
  children: React.ReactNode;
} & ButtonProps) => {
  let ref = React.useRef(null);
  let { buttonProps } = useButton(props, ref);
  return (
    <Button
      {...buttonProps}
      ref={ref}
      variant={variant}
      size={size}
      className={className}
    >
      {props.children}
    </Button>
  );
};

export const NumberField = ({
  withButtons = false,
  className,
  size,
  invalid,
  ...props
}: Omit<NumberFieldStateOptions, 'locale'> & {
  withButtons?: boolean;
  className?: string;
  size?: InputGroupProvider['size'];
  invalid?: boolean;
}) => {
  let { locale } = useLocale();
  let state = useNumberFieldState({ ...props, locale });
  let inputRef = React.useRef(null);

  let {
    labelProps,
    groupProps,
    inputProps,
    incrementButtonProps,
    decrementButtonProps,
  } = useNumberField(props, state, inputRef);

  return (
    <div {...groupProps} className='flex w-full flex-row items-center relative'>
      <Input
        {...inputProps}
        invalid={invalid}
        ref={inputRef}
        size={size}
        name={props?.name}
        className={cn('w-full', className)}
      />

      {withButtons && (
        <div className='absolute right-0'>
          <div className='flex flex-col items-center justify-center bg-gray-100 rounded-md'>
            <NumberButton
              className='rounded-b-none rounded-l-none h-5'
              type='button'
              size='xs'
              variant='outline'
              {...incrementButtonProps}
            >
              <IconChevronUp className='w-4 h-4' />
            </NumberButton>
            <NumberButton
              className='rounded-t-none rounded-l-none h-5'
              size='xs'
              type='button'
              variant='outline'
              {...decrementButtonProps}
            >
              <IconChevronDown className='w-4 h-4' />
            </NumberButton>
          </div>
        </div>
      )}
    </div>
  );
};
