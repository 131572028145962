export interface CustomEventMap {
  'select-open-change': {
    open: boolean;
  };
}

export const buildCustomEvent = <T extends keyof CustomEventMap>(
  type: T,
  detail: CustomEventMap[T],
) =>
  new CustomEvent(type, {
    detail,
  });

export const listenCustomEvent = <T extends keyof CustomEventMap>(
  type: T,
  callback: (e: CustomEvent<CustomEventMap[T]>) => void,
) => window.addEventListener(type as any, callback);

export const stopListenCustomEvent = <T extends keyof CustomEventMap>(
  type: T,
  callback: (e: CustomEvent<CustomEventMap[T]>) => void,
) => window.removeEventListener(type as any, callback);
