export const getScrollbarWidth = () => {
  if (typeof window === 'undefined') return 0;

  const outer = window.document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  window.document.body.appendChild(outer);

  const inner = window.document.createElement('div');
  outer.appendChild(inner);

  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  outer.parentNode!.removeChild(outer);

  return scrollbarWidth;
};
