'use client';

import { CodeSandboxLogoIcon } from '@radix-ui/react-icons';
import {
  Column,
  ColumnDef,
  InitialTableState,
  OnChangeFn,
  PaginationState,
  Row,
  RowSelectionState,
  SortingState,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  Button,
  DataTablePagination,
  ScrollArea,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  cn,
} from 'ui';

export const DataTableTitle = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return <h1 className={cn('font-bold text-2xl', className)}>{children}</h1>;
};

export const DataTableContainer = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={cn('w-full max-w-7xl space-y-4', className)}>
      {children}
    </div>
  );
};

export const DataTableHeader = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={cn('flex flex-row items-center justify-between', className)}
    >
      {children}
    </div>
  );
};

export const DataTableDescription = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return <p className={cn('text-sm zinc-400', className)}>{children}</p>;
};

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  emptyStateMessage?: string;
}

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  CellWrapper?: React.FC<{
    row: TData;
    children: React.ReactNode;
    column: Column;
  }>;
}

export function DataTable<TData, TValue>({
  emptyStateMessage = 'No se encontraron resultados.',
  columns,
  data,
  CellWrapper,
  withPagination = false,
  onClickRow,
  tableRowClassName,
  className,
  entityLabel,
  align = 'left',
  sorting,
  onSortingChange,
  rowSelection = {},
  onRowSelectionChange,
  enableRowSelection = false,
  initialState,
}: DataTableProps<TData, TValue> & {
  tableRowClassName?: ((row: Row<TData>) => string) | string;
  withPagination?: boolean;
  onClickRow?: (row: Row<TData>) => void;
  className?: string;
  entityLabel?: string;
  align?: 'center' | 'left';
  sorting?: SortingState;
  onSortingChange?: OnChangeFn<SortingState>;
  rowSelection?: RowSelectionState;
  onRowSelectionChange?: OnChangeFn<RowSelectionState>;
  enableRowSelection?: boolean;
  initialState?: InitialTableState;
}) {
  const table = useReactTable({
    data,
    columns,
    onSortingChange,
    onRowSelectionChange,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: withPagination ? getPaginationRowModel() : undefined,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
      rowSelection,
    },
    enableRowSelection,
    manualSorting: true,
    initialState,
  });

  return (
    <div className={className}>
      <div className='rounded-md border'>
        <Table className='relative'>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      className={cn({
                        'text-center': align === 'center',
                      })}
                      key={header.id}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  className={cn(
                    tableRowClassName
                      ? typeof tableRowClassName === 'string'
                        ? tableRowClassName
                        : tableRowClassName(row)
                      : '',
                    'table-row',
                  )}
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                  onClick={(ev) => {
                    const element = ev.target as HTMLElement;
                    if (element.tagName !== 'TD') {
                      return;
                    }

                    if (onClickRow) {
                      onClickRow(row);
                    }
                  }}
                >
                  {row.getVisibleCells().map((cell) => {
                    if (CellWrapper) {
                      return (
                        <TableCell className='relative' key={cell.id}>
                          <CellWrapper column={cell.column} row={row.original}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </CellWrapper>
                        </TableCell>
                      );
                    }

                    return (
                      <TableCell key={cell.id}>
                        <div
                          className={cn(
                            {
                              '!pr-4 flex items-center justify-center':
                                align === 'center' &&
                                !!(cell.column.columnDef.meta as any)?.align,
                            },
                            'w-fit',
                          )}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </div>
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className='h-24 text-center'
                >
                  {emptyStateMessage}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      {withPagination && (
        <DataTablePagination entityLabel={entityLabel!} table={table} />
      )}
    </div>
  );
}
