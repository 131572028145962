import { Icon, IconLock, IconLockOpen } from '@tabler/icons-react';
import { ClaimSystemStatus } from 'database';
import { cn } from '../lib/utils';

export const systemStatusIco: Record<ClaimSystemStatus, Icon> = {
  open: IconLockOpen,
  closed: IconLock,
};

export default function ClaimSystemStatusIcon({
  systemStatus,
  className,
}: {
  systemStatus: ClaimSystemStatus;
  className?: string;
}) {
  const Icon = systemStatusIco[systemStatus];

  return (
    <Icon
      className={cn(
        'size-6',
        {
          'text-lime-400': systemStatus === 'open',
          'text-red-400': systemStatus === 'closed',
        },
        className,
      )}
    />
  );
}
