import { useEffect, useState } from 'react';
import {
  ScrollArea,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'ui';
import { useQuery } from '@tanstack/react-query';

interface SelectOption {
  label: string;
  value: string;
}

export const AsyncSelect = ({
  loadOptions,
  onSelect,
  value,
  cacheQueryKey,
}: {
  loadOptions: () => Promise<SelectOption[]>;
  onSelect: (value: string, option: SelectOption) => void;
  value?: string;
  cacheQueryKey: string;
}) => {
  const query = useQuery({
    queryKey: [cacheQueryKey],
    queryFn: loadOptions,
  });

  return (
    <Select
      disabled={query.isLoading}
      defaultValue={value}
      onValueChange={(value) =>
        onSelect(value, (query.data || []).find((opt) => opt.value === value)!)
      }
    >
      <SelectTrigger>
        <SelectValue
          placeholder={
            query.isLoading ? 'Cargando opciones...' : 'Elegí una opción'
          }
        />
      </SelectTrigger>
      <SelectContent className='h-full' asChild>
        {!query.isLoading && query.data && (
          <ScrollArea
            orientation='vertical'
            className='h-full max-h-40 flex flex-col'
          >
            {query.data.map((option) => {
              return (
                <SelectItem key={option.value} value={option.value}>
                  {option.label}
                </SelectItem>
              );
            })}
          </ScrollArea>
        )}
      </SelectContent>
    </Select>
  );
};
