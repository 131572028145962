'use client';

import { IconCheck, IconFilter } from '@tabler/icons-react';
import * as React from 'react';
import { cn } from '../lib/utils';
import { Button, ButtonProps } from './Button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from './Command';
import { Popover, PopoverContent, PopoverTrigger } from './Popover';
import { ScrollArea } from './ScrollArea';
import { Icon } from '@tabler/icons-react';
import { sortListAlphabetically } from 'utils';

interface Option<T extends string> {
  label: string;
  value: T;
}

export function FancyBox<T extends string>({
  options,
  onValueChange,
  selectedValues = [],
  emptyStateMessage = 'Ninguna opción',
  singularLabel = 'opción seleccionada',
  pluralLabel = 'opciones seleccionadas',
  inputPlaceholder = 'Buscar opciones',
  btnClassName,
  btnSize,
  LeftIcon,
  RightIcon,
  containerClassName,
  setLastFilter,
}: {
  selectedValues?: Option<T>[];
  options: Option<T>[];
  onValueChange: (selected: Option<T>[]) => void;
  pluralLabel?: string;
  singularLabel?: string;
  emptyStateMessage?: string;
  inputPlaceholder?: string;
  btnClassName?: string;
  btnSize?: ButtonProps['size'];
  LeftIcon?: Icon;
  RightIcon?: Icon;
  containerClassName?: string;
  setLastFilter?: (value: string) => void;
}) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [openCombobox, setOpenCombobox] = React.useState(false);
  const [inputValue, setInputValue] = React.useState<string>('');

  const toggleOption = (option: Option<T>) => {
    const optionNoSelected = !(selectedValues || []).some(
      (selected) => selected.value === option.value,
    );

    if (setLastFilter && optionNoSelected) setLastFilter(option.value);

    onValueChange(
      optionNoSelected
        ? [...(selectedValues || []), option]
        : (selectedValues || []).filter((l) => l.value !== option.value),
    );
    inputRef?.current?.focus();
  };

  const [buttonWidth, setButtonWidth] = React.useState(0);

  const onComboboxOpenChange = (value: boolean) => {
    inputRef.current?.blur(); // HACK: otherwise, would scroll automatically to the bottom of page
    setOpenCombobox(value);
  };

  const normalizeString = (str: string = '') => str.toLowerCase().trim();

  const previewLabel = React.useMemo(() => {
    if (selectedValues.length === 0) return inputPlaceholder;

    if (selectedValues.length === 1) return singularLabel;

    if (selectedValues.length > 1)
      return `${selectedValues.length} ${pluralLabel}`;
  }, [selectedValues.length]);

  return (
    <div className={cn('max-w-[200px]', containerClassName)}>
      <Popover open={openCombobox} onOpenChange={onComboboxOpenChange}>
        <PopoverTrigger asChild>
          <Button
            ref={(ele) => {
              if (ele) {
                setButtonWidth(ele.offsetWidth);
              }
            }}
            variant='outline'
            role='combobox'
            size={btnSize}
            aria-expanded={openCombobox}
            className={cn(
              'w-[200px] justify-start text-foreground',
              btnClassName,
            )}
          >
            {LeftIcon && (
              <LeftIcon className='mr-2 h-4 w-4 shrink-0 opacity-50' />
            )}

            <span className='truncate'>{previewLabel}</span>
            {RightIcon && (
              <div className='absolute right-2'>
                <RightIcon className='h-4 w-4 shrink-0 opacity-50' />
              </div>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent
          style={{
            width: buttonWidth,
          }}
          className='p-0'
        >
          <Command
            filter={(value, search) => {
              const opt = options.find(
                (opt) => opt.value.toLowerCase() === value.toLowerCase(),
              )!;

              if (normalizeString(opt?.label).includes(search)) return 1;

              return 0;
            }}
            loop
          >
            <CommandInput
              ref={inputRef}
              placeholder={inputPlaceholder}
              value={inputValue}
              onValueChange={setInputValue}
            />
            <CommandEmpty>{emptyStateMessage}</CommandEmpty>
            <CommandGroup>
              <ScrollArea orientation='vertical' className='flex flex-col'>
                {sortListAlphabetically(options, 'label').map((option) => {
                  const isActive = selectedValues.some(
                    (selected) => selected.value === option.value,
                  );

                  return (
                    <CommandItem
                      key={option.value}
                      value={option.value}
                      onSelect={() => toggleOption(option)}
                    >
                      <IconCheck
                        className={cn(
                          'mr-2 h-4 w-4',
                          isActive ? 'opacity-100' : 'opacity-0',
                        )}
                      />
                      <div className='flex-1'>{option.label}</div>
                    </CommandItem>
                  );
                })}
              </ScrollArea>
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
}
