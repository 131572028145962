import { Separator } from '@radix-ui/react-separator';
import { Skeleton } from './Skeleton';

export const MessagesLoadingState = () => {
  const SAMPLE_MESSAGES_COUNT = 5;

  return (
    <div className='flex flex-col'>
      {new Array(SAMPLE_MESSAGES_COUNT).fill(null).map((_, index) => {
        return (
          <div key={index}>
            <div className='flex items-center space-x-4'>
              <Skeleton className='h-12 w-12 rounded-full' />
              <div className='space-y-2'>
                <Skeleton className='h-4 w-[250px]' />
                <Skeleton className='h-4 w-[200px]' />
              </div>
            </div>
            {index + 1 !== SAMPLE_MESSAGES_COUNT && (
              <Separator className='my-6' />
            )}
          </div>
        );
      })}
    </div>
  );
};
