import { useCallback, useMemo, useState } from 'react';
import debounce from 'lodash.debounce';

export const useDebouncedSearch = (wait: number = 500) => {
  const [query, setQuery] = useState('');

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  }, []);

  const onChangeDebounced = useMemo(() => debounce(onChange, wait), []);

  return {
    query,
    setQuery,
    onChange: onChangeDebounced,
  };
};
