'use client';

import { IconCheck, IconChevronDown } from '@tabler/icons-react';
import { Button, ButtonProps } from './Button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from './Command';
import { Popover, PopoverContent, PopoverTrigger } from './Popover';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { cn } from '../lib/utils';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from './Tooltip';
import { applyConditionsToVariables } from 'bff';

export const ComboboxButton = React.forwardRef<
  HTMLButtonElement,
  ButtonProps & {
    placeholder?: string;
    selected?: Pick<ComboboxOption, 'label'>;
    readonly?: boolean;
    withClear?: boolean;
  }
>(
  (
    {
      className,
      placeholder = 'Selecciona una opción',
      selected,
      size = 'input-sm',
      readonly = false,
      withClear = false,
      ...props
    },
    ref,
  ) => {
    const ButtonContent = (
      <Button
        disabled={!selected?.label}
        variant='outline'
        role='combobox'
        className={cn(
          'justify-between truncate max-w-xs w-full flex relative',
          className,
        )}
        data-test-id='combobox'
        size={size}
        {...props}
        ref={ref}
      >
        {selected ? selected.label : placeholder}
        {!readonly && (
          <div className='absolute right-2'>
            <IconChevronDown className='ml-2 h-4 w-4 shrink-0 opacity-50' />
          </div>
        )}
      </Button>
    );

    if (selected?.label) {
      return (
        <TooltipProvider delayDuration={0}>
          <Tooltip>
            <TooltipTrigger asChild>{ButtonContent}</TooltipTrigger>
            <TooltipContent>
              <p>{selected?.label}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      );
    }

    return ButtonContent;
  },
);

export interface ComboboxOption<T = string | number> {
  label: string;
  value: T;
  options?: ComboboxOption[];
  group?: string;
}

export const ComboboxCheckbox = <T extends ComboboxOption>({
  render: RenderOption,
  value,
  loadOptions,
  onSelect,
  placeholder,
  emptyStateMessage = 'No se encontraron resultados',
  defaultOptions = [],
  icon,
  disabled = false,
  dependencyState = [],
  defaultValue,
  size = 'sm',
  className,
  onClose,
  withEmptySearchTerm = false,
}: {
  icon?: React.ReactNode;
  loadOptions: (search: string) => Promise<T[]>;
  onSelect: (value: T) => void;
  placeholder: string;
  emptyStateMessage: string;
  render: React.FC<T>;
  value: ComboboxOption[];
  defaultOptions: ComboboxOption[];
  disabled?: boolean;
  dependencyState?: string[];
  defaultValue?: ComboboxOption | null | undefined;
  size?: 'xs' | 'sm' | 'lg';
  className?: string;
  onClose: () => void;
  withEmptySearchTerm?: boolean;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const [opts, setOpts] = useState<T[]>(
    !!defaultOptions && Array.isArray(defaultOptions) ? defaultOptions : [],
  );

  const wrappedFunc = useCallback(
    (search: string = '') => {
      if (!search && !withEmptySearchTerm) {
        setOpts([]);
        setIsLoading(false);
        return;
      }

      loadOptions(search)
        .then((res) => {
          setOpts(res);
        })
        .finally(() => setIsLoading(false));
    },
    [...dependencyState],
  );

  const debounced = useCallback(debounce(wrappedFunc, 500), [
    ...dependencyState,
  ]);

  useEffect(() => {
    loadOptions('').then(setOpts);
  }, []);

  return (
    <Command shouldFilter={false}>
      <CommandInput
        disabled={disabled}
        loading={isLoading}
        placeholder={placeholder}
        onValueChange={(search) => {
          setIsLoading(true);
          debounced(search);
        }}
      />

      <CommandList>
        <CommandEmpty>
          {isLoading ? 'Buscando...' : emptyStateMessage}
        </CommandEmpty>
        <CommandGroup>
          {(opts || []).map((opt, index) => {
            const selected = value.some(
              (ele) => ele.value.toString() === opt.value.toString(),
            );

            return (
              <CommandItem
                key={index}
                value={opt.value.toString()}
                onSelect={() => onSelect(opt)}
              >
                <div
                  className={cn(
                    'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                    {
                      'bg-primary text-primary-foreground': selected,
                      'opacity-50 [&_svg]:invisible': !selected,
                    },
                  )}
                >
                  <IconCheck className='w-4 h-4' />
                </div>
                <RenderOption {...opt} />
              </CommandItem>
            );
          })}
        </CommandGroup>
      </CommandList>
    </Command>
  );
};

export function Combobox<T extends ComboboxOption>({
  render: RenderOption,
  value,
  loadOptions,
  onSelect,
  placeholder,
  emptyStateMessage,
  defaultOptions = [],
  icon,
  disabled = false,
  dependencyState = [],
  defaultValue,
  size = 'sm',
  className,
  mode = 'sync',
  popoverClassName,
  withClear = false,
}: {
  icon?: React.ReactNode;
  loadOptions: (search: string) => Promise<T[]>;
  onSelect: (value: T, notifyCommit?: () => void) => void;
  placeholder: string;
  emptyStateMessage: string;
  render: React.FC<T>;
  value?: string;
  defaultOptions: ComboboxOption[];
  disabled?: boolean;
  dependencyState?: string[];
  defaultValue?: ComboboxOption[] | null | undefined | ComboboxOption;
  size?: 'xs' | 'sm' | 'lg' | 'input' | 'input-sm';
  className?: string;
  mode?: 'async' | 'sync';
  popoverClassName?: string;
  withClear?: boolean;
}) {
  const findOpt = useCallback(
    (opts: T[], target: string, key: keyof T) =>
      opts.find((opt) => opt[key] === target),
    [],
  );

  const [buttonWidth, setButtonWidth] = useState(0);

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [opts, setOpts] = useState<T[]>(() => {
    if (defaultValue && !Array.isArray(defaultValue) && !defaultOptions.length)
      return [defaultValue];

    return !!defaultOptions && Array.isArray(defaultOptions)
      ? defaultOptions
      : [];
  });

  const [selected, setSelected] = useState<T | undefined>(() => {
    if (defaultValue) {
      return defaultValue;
    }

    if (value) {
      return opts;
    }

    return undefined;
  });

  const wrappedFunc = useCallback(
    (search: string = '') => {
      if (!search) {
        setOpts([]);
        setIsLoading(false);
        return;
      }

      loadOptions(search)
        .then((res) => {
          setOpts(res);
        })
        .finally(() => setIsLoading(false));
    },
    [...dependencyState],
  );

  const debounced = useCallback(debounce(wrappedFunc, 500), [
    ...dependencyState,
  ]);

  console.log('opts', opts, 'selected', selected);

  return (
    <Popover
      open={open}
      onOpenChange={(open) => {
        if (disabled) return;

        setOpen(open);
      }}
    >
      <PopoverTrigger asChild>
        <Button
          size={size}
          variant='outline'
          role='combobox'
          aria-expanded={open}
          className={cn(
            'justify-between truncate max-w-xs w-full flex relative',
            className,
          )}
          ref={(ele) => {
            if (ele) {
              setButtonWidth(ele.offsetWidth);
            }
          }}
          data-test-id='combobox'
        >
          {selected ? selected.label : placeholder}

          {!disabled &&
            (icon || (
              <div className='absolute right-2'>
                <IconChevronDown className='ml-2 h-4 w-4 shrink-0 opacity-50' />
              </div>
            ))}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        align='center'
        className={cn('w-full p-0 popover-content', popoverClassName)}
        style={{
          width: buttonWidth,
        }}
      >
        <Command className='w-full' shouldFilter={false}>
          <CommandInput
            className='w-full'
            disabled={disabled}
            loading={isLoading}
            placeholder={placeholder}
            onValueChange={(search) => {
              setIsLoading(true);
              debounced(search);
            }}
          />

          <CommandList>
            <CommandEmpty>
              {isLoading ? 'Buscando...' : emptyStateMessage}
            </CommandEmpty>
            {(opts || []).map((opt, index) => {
              if (opt.options) {
                return (
                  <CommandGroup key={index} heading={opt.label}>
                    {opt.options.length > 0 ? (
                      opt.options.map((groupOpt, index) => {
                        const value = `${groupOpt.value}-${opt.value}`;

                        return (
                          <CommandItem
                            key={index}
                            value={value}
                            onSelect={(currentValue) => {
                              onSelect(
                                currentValue === value ? '' : currentValue,
                              );

                              setSelected((prev) =>
                                prev.concat([
                                  findOpt(opts, currentValue, 'value'),
                                ]),
                              );
                              setOpen(false);
                            }}
                          >
                            <RenderOption {...groupOpt} group={opt.value} />
                          </CommandItem>
                        );
                      })
                    ) : (
                      <p className='text-sm pl-2'>
                        No se encontraron resultados
                      </p>
                    )}
                  </CommandGroup>
                );
              }

              return (
                <CommandItem
                  key={index}
                  value={opt.value}
                  onSelect={(currentValue) => {
                    const isClearing = value === opt.value && withClear;

                    const localCommit = () => {
                      const selectedOpt = findOpt(opts, currentValue, 'value');

                      if (isClearing) {
                        setSelected(null);
                      }

                      if (!isClearing) {
                        setSelected(selectedOpt);
                      }

                      setOpen(false);
                    };

                    if (mode === 'sync') {
                      onSelect(isClearing ? null : opt);
                      localCommit();
                    }

                    if (mode === 'async') {
                      onSelect(isClearing ? null : opt, localCommit);
                    }
                  }}
                >
                  <RenderOption {...opt} />
                </CommandItem>
              );
            })}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
