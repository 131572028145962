import { useState } from 'react';

interface DateRange {
  from?: Date | undefined;
  to?: Date | undefined;
}

export const useDateRange = (
  defaultValue?: DateRange,
  intercept?: (values: DateRange) => void,
) => {
  const [date, setDate] = useState<DateRange | undefined>(defaultValue);

  return {
    date,
    getDateRangeProps: () => {
      return {
        setDate: (value: DateRange) => {
          if (intercept) {
            intercept(value);
          }

          setDate(value);
        },
        date,
      };
    },
  };
};

export type UseDateRangeProps = ReturnType<
  ReturnType<typeof useDateRange>['getDateRangeProps']
>;
