'use client';

import { CalendarIcon } from '@radix-ui/react-icons';
import { format } from 'date-fns';
import * as React from 'react';

import { UseDateRangeProps } from '../hooks/useDateRange';
import { cn } from '../lib/utils';
import { Button } from './Button';
import { DefaultCalendar } from './DefaultCalendar';
import { Popover, PopoverContent, PopoverTrigger } from './Popover';
import { useClickAway } from '@uidotdev/usehooks';

export function DateRangePicker({
  className,
  date,
  setDate,
  btnClassName,
  align = 'start',
  placeholder = 'Selecciona una fecha',
}: React.HTMLAttributes<HTMLDivElement> &
  UseDateRangeProps & {
    btnClassName?: string;
    align?: React.ComponentPropsWithoutRef<typeof PopoverContent>['align'];
    placeholder?: string;
  }) {
  const [open, setOpen] = React.useState(false);

  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const ref = useClickAway((e) => {
    const intersectionButton = buttonRef.current?.contains(e.target as Node);

    if (intersectionButton) return;

    setOpen(false);
  });

  return (
    <div className={cn('grid gap-2', className)}>
      <Popover open={open}>
        <PopoverTrigger asChild>
          <Button
            onClick={() => {
              console.log('on click', open);

              if (open) {
                setOpen(false);
              }

              if (!open) {
                setOpen(true);
              }
            }}
            ref={buttonRef}
            id='date'
            variant={'outline'}
            className={cn(
              'w-[260px] justify-start text-left font-normal',
              {
                'text-muted-foreground': !date,
              },
              btnClassName,
            )}
          >
            <CalendarIcon className='mr-2 h-4 w-4' />
            {date?.from ? (
              date.to ? (
                <>
                  {format(date.from, 'LLL dd, y')} -{' '}
                  {format(date.to, 'LLL dd, y')}
                </>
              ) : (
                format(date.from, 'LLL dd, y')
              )
            ) : (
              <span>{placeholder}</span>
            )}
          </Button>
        </PopoverTrigger>

        <PopoverContent ref={ref} className='w-auto p-0' align={align}>
          <DefaultCalendar
            defaultMonth={date?.from}
            selected={date}
            onSelect={setDate}
            numberOfMonths={2}
            mode='range'
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}
